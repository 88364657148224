.app--main {
  width: 325px;
}

@media (min-width: 990px) {
  .app--main {
    width: 410px;
  }
}

.app--main .elastic-logo {
  background: white;
  border-radius: 50%;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  margin: 2em auto 2em;
  width: 100px;
}
